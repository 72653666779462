<template>
  <div class="container mx-auto">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "Container",
};
</script>
